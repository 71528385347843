<template>
  <div>
    <Header :navbg='true'></Header>
    <div class="content">

      <van-swipe :autoplay="3000" indicator-color="white" class="banner">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index">
          <van-image :src="item.image" fit="cover" class="image" />
        </van-swipe-item>
      </van-swipe>

      <div class="main1">
        <div class="page">
          <div class="title-wrap">
            <div class="title">绿色低层庭院式住宅</div>
            <div class="title">小院科技在渭南市</div>
            <div class="title">第一个<span>低层花园</span>社区</div>
            <div class="title">紧邻渭南中学</div>
            <div class="title">距离渭南北站</div>
            <div class="title">直线距离仅6.3公里</div>
          </div>
          <div class="img-wrap">
            <img src="../../../../assets/images/project/future-city/bg1.jpg" alt="">
          </div>
        </div>
      </div>

      <div class="main2">
        <div class="page">
          <div class="image-title">
            <img src="../../../../assets/images/project/future-city/jiangxin.png" alt="">
          </div>
          <div class="list">
            <div class="item" v-for="(item,index) in houseTypeList" :key="index">
              <div class="area">{{item.name}}</div>
              <div class="desc1">{{item.desc1}}</div>
              <div class="desc2" v-if="item.desc2">{{item.desc2}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="main3">
        <div class="page">
          <div class="title-wrap">
            心有境界 院无界
          </div>
          <div class="desc-wrap">
            <div class="desc">赠送南北双花园</div>
            <div class="desc">前院为私家花园，后院为私家停车位</div>
            <!-- <div class="desc">配备停车位</div> -->
            <div class="desc">可安装充电桩</div>
            <div class="desc">户户都可方便使用新能源汽车</div>
          </div>
          <div class="image-wrap">
            <div class="item">
              <img src="../../../../assets/images/project/detail/shuang1.png" alt="">
              <div class="title">室外花园</div>
            </div>
            <div class="item">
              <img src="../../../../assets/images/project/detail/shuang2.png" alt="">
              <div class="title">会客空间</div>
            </div>
            <div class="item">
              <img src="../../../../assets/images/project/detail/shuang3.png" alt="">
              <div class="title">儿童活动</div>
            </div>
            <div class="item">
              <img src="../../../../assets/images/project/detail/shuang4.png" alt="">
              <div class="title">宠物之家</div>
            </div>
          </div>

        </div>
      </div>

      <div class="main4">
        <div class="page">
          <div class="icon-list-wrap">
            <div class="icon-wrap" v-for="(item,index) in iconList" :key="index">
              <div class="iconfont" :class="item.icon"></div>
              <div class="name">{{item.name}}</div>
              <div class="desc">{{item.desc}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="main5">
        <div class="page">
          <div class="title">优质房源</div>
          <div class="iconfont-list">
            <div class="warp">
              <div class="item">
                <i class="iconfont icon-safe"></i>
                <div class="title">抗震性最好</div>
                <div class="desc">短肢剪力墙结构</div>
                <div class="desc">现浇结构至少30％</div>
              </div>
              <div class="item">
                <i class="iconfont icon-punch"></i>
                <div class="title">降板同层排水</div>
                <div class="desc">
                  洁具位置不受限
                </div>
                <div class="desc">低噪声+舒适高度+维修便捷</div>
              </div>
              <div class="item">
                <i class="iconfont icon-brand"></i>
                <div class="title">热水循环</div>
                <div class="desc">
                  多点位同时供应
                </div>
                <div class="desc">舒适，节水，节能</div>
              </div>
              <div class="item">
                <i class="iconfont icon-we"></i>
                <div class="title">断桥铝窗外开窗</div>
                <div class="desc">
                  气密性更好
                </div>
                <div class="desc">不占室内空间</div>
              </div>
            </div>
            <div class="warp">
              <div class="item">
                <i class="iconfont icon-square"></i>
                <div class="title">VELUX天窗</div>
                <div class="desc">
                  引光入室+增加自然通风
                </div>
                <div class="desc">防水更好，可定制升级</div>
              </div>
              <div class="item">
                <i class="iconfont icon-evaluate"></i>
                <div class="title">新风预留</div>
                <div class="desc">
                  净化室内空气
                </div>
                <div class="desc">除菌、消毒、祛湿</div>
              </div>
              <div class="item">
                <i class="iconfont icon-flashlightopen"></i>
                <div class="title">空气源热泵</div>
                <div class="desc">
                  温度波动小，无风口
                </div>
                <div class="desc">冬夏一套设备</div>
              </div>
              <div class="item">
                <i class="iconfont icon-shop"></i>
                <div class="title">光伏屋面</div>
                <div class="desc">
                  绿色低碳
                </div>
                <div class="desc">节能减耗</div>
              </div>
            </div>
          </div>
        </div>
        <div class="img-wrap">
          <img src="../../../../assets/images/project/future-city/youzhi.jpg" alt="">
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/head2022.vue'
import Footer from '@/components/foot2022.vue'
import utils from '@/common/utils'

export default {
  name:"futureCity",
  data () {
    return {
      bannerList: [
        {
          image: require("../../../../assets/images/project/future-city/banner.jpg"),
        },
      ],
      houseTypeList: [{
      		name: "A户型",
      		desc1: "建筑面积约105㎡",
      		desc2: "可扩展面积至196㎡",
      	},
      	{
      		name: "C1户型",
      		desc1: "建筑面积约160㎡",
      		desc2: "可扩展面积至252㎡",
      	},
      	{
      		name: "D1户型",
      		desc1: "建筑面积约217㎡",
      		desc2: "可扩展面积至327㎡",
      	},
      	{
      		name: "E1户型",
      		desc1: "建筑面积约210㎡",
      		desc2: "可扩展面积至320㎡",
      	},
      	{
      		name: "F1户型",
      		desc1: "建筑面积约148㎡",
      		desc2: "可扩展面积至231㎡",
      	},
      	{
      		name: "小院商居1",
      		desc1: "建筑面积约210㎡",
      		desc2: "可扩展面积至293㎡",
      	},
      	{
      		name: "B户型",
      		desc1: "建筑面积约138㎡",
      		desc2: "可扩展面积至222㎡",
      	},
      	{
      		name: "C2户型",
      		desc1: "建筑面积约163㎡",
      		desc2: "可扩展面积至258㎡",
      	},
      	{
      		name: "D2户型",
      		desc1: "建筑面积约211㎡",
      		desc2: "可扩展面积至321㎡",
      	},
      	{
      		name: "E2户型",
      		desc1: "建筑面积约200㎡",
      		desc2: "可扩展面积至310㎡",
      	},
      	{
      		name: "F2户型",
      		desc1: "建筑面积约150㎡",
      		desc2: "可扩展面积至233㎡",
      	},
      	{
      		name: "小院商居2",
      		desc1: "建筑面积约212㎡",
      		desc2: "可扩展面积至295㎡",
      	},
      	{
      		name: "小院旺铺",
      		desc1: "建筑面积约60㎡",
      		// desc2: "可扩展面积至295㎡",
      	},
      ],
      iconList: [
        {
          icon: "icon-structure",
          name: "剪力墙结构",
          desc: "安全抗震性极高"
        },
        {
          icon: "icon-Aluminiumalloysvg",
          name: "断桥铝合金",
          desc: "坚固耐久、隔音隔热、抗风压"
        },
        {
          icon: "icon-circulatorysystem",
          name: "热水循环系统",
          desc: "实现热水即开即用"
        }
      ],
    }
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    houseType: utils.debounce(function (e) {
      this.$router.push({ name: 'productDooryardDetail', params: { houseType: e } });
    }, 500, true),

    projectClick: utils.debounce(function (name) {
      this.$router.push({ name });
    }, 500, true),

  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  .banner {
    width: 100%;
  }
  .main1 {
    width: 100%;
    text-align: center;
    .title-wrap {
      .title {
        font-size: 13px;
        color: #292929;
        line-height: 13px;
        > span {
          font-weight: 600;
        }
        &:nth-of-type(1) {
          font-size: 18px;
          font-weight: 600;
          margin-top: 70px;
        }
        &:nth-of-type(2) {
          margin-top: 24px;
        }
        &:nth-of-type(3) {
          margin-top: 7px;
        }
        &:nth-of-type(4) {
          margin-top: 15px;
        }
        &:nth-of-type(5) {
          margin-top: 15px;
        }
        &:nth-of-type(5) {
          margin-top: 7px;
        }
      }
    }
    .img-wrap {
      width: 100%;
      margin-top: 47px;
      > img {
        width: 100%;
      }
    }
  }
  .main2 {
    width: 100%;
    background-image: url('../../../../assets/images/project/future-city/jiangxin.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 22px 0px 0px;
    box-sizing: border-box;
    .image-title {
      text-align: center;
      > img {
        width: 105px;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      font-size: 12px;
      color: #fff;
      justify-content: space-around;
      align-items: center;
      .item {
        width: 33%;
        height: 76px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        .area {
          display: inline-block;
          width: 75px;
          height: 18px;
          line-height: 18px;
          color: #303233;
          background-color: #fff;
          border-radius: 100px;
          text-align: center;
        }
        .desc1 {
          margin-top: 10px;
          line-height: 12px;
        }
        .desc2 {
          margin-top: 6px;
          line-height: 12px;
        }
      }
    }
  }
  .main3 {
    width: 100%;
    .page {
      width: 100%;
      padding: 31px 15px 41px 15px;
      box-sizing: border-box;
      .title-wrap {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: #454552;
      }
      .desc-wrap {
        font-size: 12px;
        line-height: 18px;
        color: #454552;
        margin-top: 28px;
        text-align: center;
      }
      .image-wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 7px;
        .item {
          width: 49%;
          position: relative;
          margin-top: 13px;
          > img {
            width: 100%;
          }
          .title {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: 900;
            color: #fff;
            z-index: 100;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
  .main4 {
    background-image: url('../../../../assets/images/project/future-city/bg3.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 30px 0 33px;
    .icon-wrap {
      width: 100%;
      text-align: center;
      color: #fff;
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 0;
      }
      .iconfont {
        font-size: 30px;
        line-height: 30px;
      }
      .name {
        font-size: 18px;
        line-height: 18px;
        margin-top: 10px;
      }
      .desc {
        color: #dddddd;
        font-size: 15px;
        line-height: 15px;
        margin-top: 6px;
      }
    }
  }
  .main5 {
    width: 100%;
    .page{
      width: 100%;
      padding: 54px 20px 38px;
      box-sizing: border-box;
      >.title{
        font-size: 18px;
        font-weight: 900;
        color: #454552;
        text-align: center;
      }
      .iconfont-list{
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        .warp{
          width: 50%;
          text-align: center;
          .item{
            margin-bottom: 30px;
            &:last-of-type{
              margin-bottom: 0;
            }
            .iconfont{
              font-size: 40px;
              color: #000000;
            }
            .title{
              font-size: 18px;
              color: #303233;
              line-height: 18px;
              margin-top: 22px;
            }
            .desc{
              font-size: 14px;
              color: #666666;
              line-height: 18px;
            }
          }
        }
      }
    }
    .img-wrap{
      width: 100%;
      >img{
        width: 100%;
      }
    }
  }
}
</style>
